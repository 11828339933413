import { Hidden } from '@material-ui/core';
import React from 'react';
import BannerBottomImg from 'src/images/futureshop/banner-bottom.png';
import styles from './BannerBottom.module.scss';

export default function BannerBottom() {
  return (
    <Hidden xsDown>
      <section className={styles.section}>
        <img src={BannerBottomImg} alt="banner-bottom" className={styles.img} />
      </section>
    </Hidden>
  );
}
