import React from 'react';
import styles from './JulyTopBanner.module.scss';

export default function JulyTopBanner() {
  return (
    <section className={styles.section}>
      <div className={styles.img} />
    </section>
  );
}
