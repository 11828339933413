import React from 'react';
import styles from './TextBottom.module.scss';

export default function TextBottom() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>対象加盟店一覧</h1>
      <p className={styles.text}>
        カラコン専門店バンビーナ、《公式》レディースシューズ通販 | AmiAmi
        アミアミ、DOUBLE HEART （ダブルハート） ONLINE
        SHOP、エアロのエース、OXOオクソー正規販売店プラスエフ、TAVARAT、ミレー公式オンラインストア、造花ドットコム、水引屋大橋丹治、業務用タオル専門店いとへん、まるげん、ozie、かねてつ水産オンラインショップ、リオネル、ｈａｄａｅオンラインショップ、Rew-you、白山屋、カラコン専門店ベルア、輸入家具・雑貨の専門店
        e木楽館【公式】、office-work.jp、いぬのための、showgirl、おしゃれなシニアのファッション通販G＆B、SPINNS
        WEB STORE、SUNSQUARE、ScoLar webstore、公式 苗木部 By 花ひろばオンライン
        本店、Gluxury、麗ビューティーオンラインショップ、Jalana | ジャラーナ
        オンラインストア、PICCIN ONLINE STORE、&Vogue
        STORE、俺の合鍵、クイーンアイズ、らぽっぽファームオンラインストア、広岡精肉店、Hedy、Carina
        closet、アクシージア 公式ショップ、ゴルフウェアショップ
        T-on、SelectBeauty STORE、M co.,ltd ONLINE
        SHOP、お名前シールラボ、お名前シール工場、パッケージ工場、リストバンド工場、名入れ工場、駐輪シール工場、ジャーマンペットオンラインショップ、AVIOT
        ONLINE MALL、MAKES ONLINE STORE、Deorart
        shop、タオルモールオルタ、Intertec Online Store、BRITISH
        MADEオンラインショップ、イレブンストア、anonenone、ME-Q、エルゴン公式ショップ、とば屋酢店、VDS
        BIRDS EYE、U-collection、とば屋酢店、【公式】メーカー直販
        第一ビニール、彫刻刀専門ショップ、VDS BIRDS EYE、MICA&DEAL ONLINE
        STORE、U-collection、うれしい洋菓子店、BLACKQUEEN、ABISTE WEB
        SHOP、カラコン専門店バンビーナ、bombshell、maniacs web
        shop、AXES、リユーセル、JULIA BOUTIQUE、LILY ANNA、PIKEY WEB STORE、shop
        yamatoya。
      </p>
    </section>
  );
}
