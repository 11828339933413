import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/FutureShop/JulyPaymentSteps';
import JulyCondition from 'src/components/Merchant/Campaign/FutureShop/JulyConditions';
import JulyTopBanner from 'src/components/Merchant/Campaign/FutureShop/JulyTopBanner';
import BannerBottom from 'src/components/Merchant/Campaign/FutureShop/BannerBottom';
import TextBottom from 'src/components/Merchant/Campaign/FutureShop/TextBottom';

const SEOProps = {
  title: SITE_METADATA.futureshop.title,
  description: SITE_METADATA.futureshop.description,
};

const FutureShop = () => {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <JulyTopBanner />
      <JulyCondition />
      <BannerBottom />
      <JulyPaymentSteps />
      <TextBottom />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
};

export default FutureShop;
