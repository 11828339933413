import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年12月1日（木）0:00～12月15日（木）23:59',
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: (
      <>
        キャンペーン期間中に対象ショップで初めてペイディを利用し、決済画面で翌月あと払いまたは{' '}
        <OutboundLink
          href="https://paidy.com/landing/plus_3pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          3回あと払い
        </OutboundLink>
        を選択して6,000円（税込）以上お買い物すると、500円キャッシュバック
        <br />
        <br />
        キャンペーン終了後には2022年12月31日までに特典分がキャッシュバックされる予定です。
        <br />
        キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
      </>
    ),
  },
  {
    title: <strong>キャンペーン対象</strong>,
    content: (
      <>
        ・初めてペイディ利用のお客様。（加盟店問わず、ペイディを初利用の方が対象となります）
        <br />
        ・上記利用の際に、一度に6,000円（税込）以上のお買い物。
      </>
    ),
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャッシュバックは、お一人様1回までとさせていただきます。
        <br />
        ・キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        <br />
        ・キャンペーン終了後2022年12月31日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        <br />
        ・キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・初利用とは、ペイデイアカウントを利用し、過去決済の確定がないことをもって、初めての利用とさせていただきます。
        <br />
        ・不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </OutboundLink>
        へご連絡ください。
      </>
    ),
  },
];

export default function JulyCondition() {
  return <SectionCampaignCondition data={DATA} />;
}
